p {
    color: black;
    
}

.AboutUs_Mission {
    color: black;
    font-weight: bold;
    font-size: 18px;
}

h3 {
    text-decoration: underline;
}

.Image_Size {
    height: 50px;
    max-width: 100%;
}

.AboutUs_Image {
    margin-left: 300px;
    margin-right: 300px;
}


.rowContent {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 50px 0px 50px 0px;
      border-bottom: 1px ridge;
      min-height: 400px;
  }

.ulMargins {
    padding-top: 50px;
}
  