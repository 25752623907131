.MainCarousel_Margins {
    margin-left: 300px;
    margin-right: 300px; 
    border-radius: 8px;
    /* box-shadow: 0px 0px 5px black; */
}

.Image_Size {
    height: 50px;
    max-width: 100%;
}

.carouselBackground {
    background-color: #9B0006;
}