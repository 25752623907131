p {
  font-size: 18px;
  font-weight: bold;
}

.rowContent {
  /* margin-top: 50px; */
  margin: 0px auto;
    padding: 50px 0px 0px 0px;
    border-bottom: 1px ridge;
    min-height: 400px;
}

.imageClass {
  /* width: 300px; */
  height: 200px;
  padding-top: 10px;
}

.imageClass2 {
  /* width: 300px; */
  height: 200px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.rowMargins {
  margin: 0px auto;
  padding: 50px 0px 0px 0px;
  border-bottom: 1px ridge;
  min-height: 400px;
}

.newRowMargins {
  margin-left: 120px;
  padding: 50px 0px 0px 130px;
  border-bottom: 1px ridge;
  min-height: 400px;
}