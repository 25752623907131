.rowContent {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 50px 0px 0px 0px;
      /* border-bottom: 1px ridge; */
      min-height: 300px;
  }

.ulMargins {
    padding-top: 25px;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 25px;
}

h3 {
    text-decoration: underline;
}

.headingPadding {
    padding-bottom: 25px;
}