.rowContent {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 50px 0px 0px 0px;
      border-bottom: 1px ridge;
      
  }

.ulMargins {
    padding-top: 50px;
    font-weight: bold;
    font-size: 18px;
}

h3 {
    text-decoration: underline;
}