.rowContent {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 50px 0px 50px 0px;
      border-bottom: 1px ridge;
      min-height: 400px;
  }

.rowContent2 {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 0px 0px 0px 0px;
      border-bottom: 1px ridge;
      min-height: 400px;
  }

.MainCarousel_Margins {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px; 
    border-radius: 8px;
    /* box-shadow: 0px 0px 5px black; */
}

.Image_Size {
    height: 50px;
    max-width: 100%;
}

.carouselBackground {
    background-color: #f0f0f0;
}

.imageClass {
  height: 200px;
  width: 200px;
}

.imageClass2 {
  height: 200px;
  width: 250px;
}