

/* .NavBar {
  background-color: #9B0006;
  color: white;
  
   text-align: center;
  color: white;
  padding-top: 0px;
  height: 125px;
  display: flex;
  padding: 10px 5px;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: space-between;
  box-shadow: 0px 0px 5px black; 
} */ 

.linkStyler {
  color: #f8f9fa;
}



.dropDownBackground {
  background-color: #9B0006;
}

.navbarBrand {
  width: 350px;
  height: 75px;
}

.navbarCollapse {
  margin-left: 240px;
  font-weight: bold;
  font-size: 20px;
}