.rowContent {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 0px 0px 50px 0px;
      border-bottom: 1px ridge;
      min-height: 400px;
  }

  .rowContent2 {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 50px 0px 50px 0px;
      border-bottom: 1px ridge;
      min-height: 250px;
  }

.imageClass2 {
    height: 200px;
    width: 250px;
  }

.imageClass {
    height: 200px;
    width: 350px;
  }

li {
    font-size: 18px;
    font-weight: bold;
}