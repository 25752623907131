.rowContent {
    /* margin-top: 50px; */
    margin: 0px auto;
      padding: 50px 0px 50px 0px;
      border-bottom: 1px ridge;
      min-height: 400px;
  }

.ulMargins {
    padding-top: 25px;
    padding-bottom: 25px;
    font-weight: bold;
    font-size: 18px;
}